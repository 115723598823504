<template>

  <div>
    <v-card>
      <v-card-title v-show="title" class="headline">{{ title }}</v-card-title>
      <v-card-subtitle v-show="subtitle">{{ subtitle }}</v-card-subtitle>
      <v-card-title v-show="!title && !subtitle" class="headline"></v-card-title>

      <div class="ml-4 mt-3">
        <v-alert
          border="left"
        >
          <div class="d-flex grey--text text--darken-3" style="flex-flow: row nowrap; font-size: 18px;">
            <v-icon color="info" class="mr-2">mdi-information</v-icon>
            <slot/>
          </div>
        </v-alert>
      </div>

      <v-card-actions>
        <Button :to="redirect" text color="primary">
          {{ btnText }}
        </Button>
      </v-card-actions>
    </v-card>
  </div>

</template>

<script>
import Button from "@/components/ui/Button";

export default {
  components: {
    Button,
  },
  props: {
    title: String,
    subtitle: String,
    btnText: String,
    redirect: String,
  },
  setup() {
    return {}
  }
}
</script>